import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSocket } from './socketcont';
import './game.css';


import coinpng from './pngs/2473294.png'

const Game = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const { socket, userName, games, userId, IDplayer, token } = useSocket();
  const [game, setGame] = useState(null);
  const [gameStarted, setGameStarted] = useState(false);
  const [timer, setTimer] = useState(60);

  const [currentTurn, setCurrentTurn] = useState(null);

  useEffect(() => {
    const currentGame = games.find(game => game.id === gameId);
    setGame(currentGame);

    setCurrentTurn(currentGame?.isXNext);
    console.log(`Game state initialized for gameId: ${gameId}`, currentGame);
  }, [games, gameId]);



  useEffect(() => {
    if (!socket) return;

    let inactivityTimer;

    const handleGameUpdate = (updatedGames) => {
      const updatedGame = updatedGames.find(game => game.id === gameId);
      setGame(updatedGame);
      setGameStarted(updatedGame?.gameStarted || false);

      setCurrentTurn(updatedGame?.isXNext);
      console.log(`Game state updated for gameId: ${gameId}`, updatedGame);

      
    };

    const handleStartGame = () => {
      setGameStarted(true);
      console.log('Game started');
    };

    const handleGameEnd = (endedGameId) => {
      if (endedGameId === gameId) {
        setTimeout(() => {
          navigate('/accountpage');
        }, 1000);
      }
    };

    

    
    const handleResetTimer = () => {
      setTimer(60); // Reset timer to 60 seconds on receiving reset event
    };
    

    socket.on('update games', handleGameUpdate);
    socket.on('start game', handleStartGame);
    socket.on('game end', handleGameEnd);
    
    socket.on('reset timer', handleResetTimer);
    
    
    return () => {
      socket.off('update games', handleGameUpdate);
      socket.off('start game', handleStartGame);
      socket.off('game end', handleGameEnd);
      
      socket.off('reset timer', handleResetTimer);
      
    };
  }, [socket, gameId, navigate]);

  useEffect(() => {
    let interval;
    if (gameStarted) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          }
          return 0;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [gameStarted, currentTurn]);

  /*useEffect(() => {
    if (!game) {
      navigate('/apage'); // Redirect to another page if game is not found
    }
  }, [game, navigate]);*/

  const handleClick = (index) => {
    if (gameStarted && game && game.players && !game.winner && !game.board[index]) {
      const isCurrentPlayerTurn = (game.isXNext && game.players[0].IDplayer === IDplayer) || (!game.isXNext && game.players[1]?.IDplayer === IDplayer);

      console.log(`Current player: ${game.players[game.isXNext ? 0 : 1]?.name} (${game.players[game.isXNext ? 0 : 1]?.IDplayer})`);
      console.log(`User ID: ${userId}`);
      console.log(`Is current player's turn: ${isCurrentPlayerTurn}`);

      if (isCurrentPlayerTurn) {
        console.log(`Emitting move event for game ${game.id} at index ${index}`);
        socket.emit('move', game.id, index);
        setTimer(60);
        /*socket.emit('reset timer', game.id);*/
      } else {
        console.log('It is not the current player\'s turn or player is not part of the game');
      }
    }
  };

  const handleRestart = () => {
    if (game) {
      socket.emit('restart game', game.id);
    }
  };

  const handleExit = () => {
    if (game) {
      socket.emit('exit game', game.id, (response) => {
        if (response.success) {
          navigate('/accountpage');
        } else {
          console.error('Error exiting game:', response.message);
        }
      });
    }
  };

  const renderPlayerName = (index) => {
    if (!game || !game.players || !game.players[index]) return <div>Waiting for player...</div>;
    const player = game.players[index];

    const isCurrentPlayerTurn = (game.isXNext && index === 0) || (!game.isXNext && index === 1);

    /*return <div>{player.name}</div>;*/
    return (
      <div>
        {player.name}
        {isCurrentPlayerTurn && <div className="timer">{timer}s</div>}
      </div>
    );
  };

  if (!game) {
    navigate('/accountpage'); // Redirect to another page if game is not found
    return null; //
    /*return <div>Loading game...</div>;*/
  }

  return (
    <>
    <div className="containerPageGame">
      <div className='gameidGame'>Game {gameId.substring(0, 3)}</div>
      <div className='gameplayersGame'>Players: {game ? game.players.length : 0}/2</div>

      <div className='gamediv'>
        <div className='playerdiv'>
          {renderPlayerName(0)}
        </div>
        <div className='gamebo'>
          <div className="bo">
            {game && game.board.map((cell, index) => (
              <button key={index} className="cell" onClick={() => handleClick(index)} disabled={!!cell || game.winner || !gameStarted}>
                {cell}
              </button>
            ))}
          </div>
        </div>
        <div className='playerdiv'>
          {renderPlayerName(1)}
        </div>
      </div>

      <div className='bot'>
        <div className='botdivtop'>
          <div>Game Pool: {game ? game.gamePool : 0}</div>
        </div>

        <div className='botdivbottom'>
          {/*<button onClick={handleRestart} className="restartButton">Restart Game</button>*/}
          <button onClick={handleExit} className="exitButton">Exit Game</button>
        </div>

        
      </div>
      <div className='belowbot'>
      {game && game.winner && (
          <div className="winner">
            <div>
            Winner: &nbsp;<strong>{game.winner}</strong> {/*({game.players[game.winner === 'X' ? 0 : 1]?.name})*/}
            </div>
          
          <div>
          +1.6 simpletokens
          </div>
          </div>
        )}
        {game && !game.winner && game.board.every(cell => cell) && <div className="winner">Draw</div>}
      
      </div>
    </div>


    <div className="containerPageGameSmall">
      <div className='gameidGame'>Game {gameId.substring(0, 3)}</div>
      <div className='gameplayersGame'>Players: {game ? game.players.length : 0}/2</div>

      <div className='gamediv'>

        <div className='playerdiv'>
          {renderPlayerName(0)}
        </div>

        <div className='gamebo'>
          <div className="bo">
            {game && game.board.map((cell, index) => (
              <button key={index} className="cell" onClick={() => handleClick(index)} disabled={!!cell || game.winner || !gameStarted}>
                {cell}
              </button>
            ))}
          </div>
        </div>


      




        <div className='playerdiv'>
          {renderPlayerName(1)}
        </div>

      </div>


      <div className='belowbot'>
      {game && game.winner && (
          <div className="winner">
            <div>
            Winner: &nbsp;<strong>{game.winner}</strong> {/*({game.players[game.winner === 'X' ? 0 : 1]?.name})*/}
            </div>
          
          <div>
          +1.6 simpletokens
          </div>
          </div>
        )}
        {game && !game.winner && game.board.every(cell => cell) && <div className="winner">Draw</div>}
      
      </div>

      <div className='bot'>
        <div className='botdivtop'>
          <div>Game Pool: {game ? game.gamePool : 0}</div>
        </div>

        <div className='botdivbottom'>
          {/*<button onClick={handleRestart} className="restartButton">Restart Game</button>*/}
          <button onClick={handleExit} className="exitButton">Exit Game</button>
        </div>

        
      </div>


      <div className='belowbot'>
      {game && game.winner && (
          <div className="winner">
            <div>
            Winner: &nbsp;<strong>{game.winner}</strong> {/*({game.players[game.winner === 'X' ? 0 : 1]?.name})*/}
            </div>
          
          <div>
          +1.6 simpletokens
          </div>
          </div>
        )}
        {game && !game.winner && game.board.every(cell => cell) && <div className="winner">Draw</div>}
      
      </div>
    </div>

    </>
  );
};

export default Game;
