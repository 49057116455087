import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSocket } from './socketcont';
import { jwtDecode } from 'jwt-decode';
import './accountpage.css';


import canjetoken from './pngs/12215511.png'
import logo from './pngs/7440181.png'
import logox from './pngs/744018x.png'
import logoo from './pngs/744018o.png'
import icon from './pngs/person.png'

function Pageaccount() {
  const { socket, totalPlayers, games, userName, balance, setGames, userId, IDplayer } = useSocket();
  const navigate = useNavigate();

  /*useEffect(() => {
    if (!socket) return;

    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('update games', (games) => {
      setGames(games);
      console.log('Games updated:', games);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      socket.off('connect');
      socket.off('update games');
      socket.off('disconnect');
    };
  }, [socket, setGames]);

  const handleJoinGame = (gameId) => {
    if (socket) {
      socket.emit('join game', gameId, (response) => {
        if (response.success) {
          console.log(`Joined game ${gameId}`);
          navigate(`/game/${gameId}`);
        }
      });
    }
  };*/

  const handleJoinGame = (gameId) => {
    if (socket) {
      socket.emit('join game', gameId, (response) => {
        if (response.success) {
          console.log(`Joined game ${gameId}`);
          navigate(`/game/${gameId}`);
        } else {
          console.log(`Failed to join game ${gameId}: ${response.message}`);
        }
      });
    }
  };

  const handleLogout = () => {
    const token = document.cookie.split('token=')[1];
    if (token) {
      const decoded = jwtDecode(token);
      console.log('Token:', token);
      console.log('Decoded:', decoded);
      if (decoded && decoded.userId) {
        console.log('Emitting logout event');
        socket.emit('logout', { token }, (response) => {
          if (response.success) {
            document.cookie = 'token=; Max-Age=0'; // Delete token
            navigate('/login'); // Redirect to login page
          } else {
            console.error('Logout failed:', response.message);
          }
        });
      } else {
        console.error('Invalid token');
      }
    } else {
      console.error('No token found');
    }
  };

  
  

  return (
    <>
    <div className="containerPageAccount">

      <div className='topmenuPage'>
      <div className='logoName'>
        
        <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
        <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>


        Tic Tac Toe Simple <div className='beta'>BETA</div>
    </div>
        
        <div className='cuentaPageBalance'><strong>Balance:&nbsp;</strong><div className='mono'>{balance.toFixed(2)}</div> </div>
        <div className='cuentaPage'>
        <button className='logoutButton' onClick={handleLogout}>Logout</button>
        </div>
      </div>
      <div className='secondtopmenuPage'>
        <div className='logoNameBot'><div className='mono'>
          
        <div className='twomono'>
          Hello:
          </div>
          </div> <strong>{userName}</strong>

          <div className='gcoin'>
          <Link to='/profile'>
          <img src={icon} alt="Coin" />
          </Link>
            
            </div>
        
        
        </div>
        <Link className='buybutton' to='/buycoins'>Comprar <strong>SIMPLETOKENS</strong></Link>
      </div>

      <div className='mepagePage'>
        <div className='gamePage'>game</div>

        <div className='mepagePageTitle'>
          {'>'} <div className='gamecoin'>1</div> coin games:
        </div>

        <div className="gameMenuPage">
          {games.map((game) => (
            <div className='gamePage' key={game.id}>
              <div>Game {game.id.substring(0, 3)}</div>
              <div>Players: {game.players.length}/2</div>
              <button className='joGameButton' onClick={() => handleJoinGame(game.id)} 
              disabled={game.gameStarted}
              style={game.gameStarted ? { backgroundColor: '#efcb70', borderColor: '#ebb837' } : {}}>
                Play
              </button>
            </div>
          ))}
        </div>

        
      </div>

      <div className='botsecondtopmenuPage'>
        <div className='logoNameBot'><div className='pmono'>Players:</div><strong>{totalPlayers}</strong></div>
        <Link className='ellbutton' to='/stor'> <strong>STOR</strong></Link>
        <Link className='ellbutton' to='/bvende'>Vende <strong>CANJECOINS</strong></Link>
      </div>
    </div>




    <div className="containerPageAccountSmall">

    <div className='topmenuPage'>
      
      <div className='logoName'>
  {/*<div className='gcoin'><img src={logo} alt="Coin" /></div>*/}
  
        <div className='glogos'>
        <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
  
        <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>
      
      </div>
  
      <div className='namelogo'>
      TICTACTOE SIMPLE
        </div>
      
      <div className='beta'>BETA</div>
  </div>

  </div>
    

<div className='topmenuPage'>
  
  
  <div className='cuentaPageBalance'>
    <strong>Balance:&nbsp;</strong> <div className='mono'>{balance.toFixed(2)}</div>
  </div>

  <div className='cuentaPageName'>

  

    <div className='logoNameBot'>
      

    <div className='gcoin'>
          <Link to='/profile'>
          <img src={icon} alt="Coin" />
          </Link>
          </div>
      <div className='twomono'>

        
        
        Hello:</div> <strong>{userName}</strong>
      
        
      
      </div>
  </div>

</div>

<div className='secondtopmenuPage'>

<div className='buttonscuentaPage'>

  
  <Link className='buybutton' to='/buycoins'>Comprar <strong>SIMPLETOKENS</strong></Link>
  <button className='logoutButton' onClick={handleLogout}>Logout</button>
  </div>

</div>

<div className='mepagePage'>
  <div className='gamePage'>game</div>

  <div className='mepagePageTitle'>
    {'>'} <div className='gamecoin'>1</div> coin games:
  </div>

  <div className="gameMenuPage">
    {games.map((game) => (
      <div className='gamePage' key={game.id}>
        <div>Game {game.id.substring(0, 3)}</div>
        <div>Players: {game.players.length}/2</div>
        <button className='joGameButton' onClick={() => handleJoinGame(game.id)} 
        disabled={game.gameStarted}
        style={game.gameStarted ? { backgroundColor: '#efcb70', borderColor: '#ebb837' } : {}}>
          Play
        </button>
      </div>
    ))}
  </div>

  
</div>

<div className='botsecondtopmenuPage'>
  <div className='botlogoNameBot'><div className='pmono'>Players:</div><strong>{totalPlayers}</strong></div>
  <Link className='ellbutton' to='/stor'> <strong>STOR</strong></Link>
  <Link className='ellbutton' to='/bvende'>Vende <strong>CANJECOINS</strong></Link>
</div>
</div>




    </>
  );
}

export default Pageaccount;
