import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import axios from 'axios';

const SOCKET_SERVER_URL = 'https://ablaze-tar-grasshopper.glitch.me';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [games, setGames] = useState([]);
  
  const [userId, setUserId] = useState('');
  const [IDplayer, setIDplayer] = useState('');
  const [userName, setUserName] = useState('');
  const [balance, setBalance] = useState(0);

  
  const [ctbalance, setCtBalance] = useState(0); // New state for ctbalance
  const [wins, setWins] = useState(0);           // New state for wins
  const [los, setLos] = useState(0);


  const [totalPlayers, setTotalPlayers] = useState(0);
  

  useEffect(() => {
    const token = document.cookie.split('token=')[1];
    const newSocket = io(SOCKET_SERVER_URL, {
      transports: ['websocket'],
      query: token ? { token } : {}
    });
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Connected to server');
      
      console.log('Socket ID received:', newSocket.id);
    });

    newSocket.on('user id', (id) => {
      console.log('User ID received from server:', id);
      setUserId(id);
      console.log('socket id', id);
    });

    newSocket.on('update games', (games) => {
      setGames(games);
      console.log('Games updated:', games);
    });

    

    newSocket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    newSocket.on('update player count', (count) => {
      setTotalPlayers(count);
      console.log('Total players updated:', count);
    });

    newSocket.on('connect_error', (error) => {
      console.log('Connection error:', error);
    });
  
    newSocket.on('reconnect', (attempt) => {
      console.log('Reconnected to server, attempt:', attempt);
    });
  
    newSocket.on('reconnect_attempt', (attempt) => {
      console.log('Reconnection attempt:', attempt);
    });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = document.cookie.split('token=')[1];
        if (!token) {
          console.log('No token found');
          return;
        }

        console.log('Fetching user details with token:', token);

        const response = await axios.get('https://ablaze-tar-grasshopper.glitch.me/user', {
          headers: { Authorization: `Bearer ${token}` }
        });

        console.log('User details response:', response);

        if (response.data.success) {
          setIDplayer(response.data.user.IDplayer);
          setUserName(response.data.user.name);
          setBalance(response.data.user.balance);
          //ADDED LINE BELOW
          console.log('User details fetched:', response.data.user);
          setUserId(response.data.user.IDplayer);
          console.log(balance)

          setCtBalance(response.data.user.ctbalance); // Set ctbalance
          setWins(response.data.user.wins);           // Set wins
          setLos(response.data.user.los); 
        }
      } catch (err) {
        console.error('Failed to fetch user details:', err);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <SocketContext.Provider value={{ socket, totalPlayers, ctbalance, wins, los, games, userName, balance, setGames, userId, IDplayer }}>
      {children}
    </SocketContext.Provider>
  );
};


/*import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import axios from 'axios';

const SOCKET_SERVER_URL = 'https://ablaze-tar-grasshopper.glitch.me';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [games, setGames] = useState([]);
  const [userId, setUserId] = useState('');
  const [IDplayer, setIDplayer] = useState('');
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const token = document.cookie.split('token=')[1];
    if (token) {
      const newSocket = io(SOCKET_SERVER_URL, {
        transports: ['websocket'],
        query: { token }
      });
      setSocket(newSocket);

      newSocket.on('connect', () => {
        console.log('Connected to server');
      });

      newSocket.on('user id', (id) => {
        setUserId(id);
        console.log('socket id', id);
      });

      newSocket.on('update games', (games) => {
        setGames(games);
        console.log('Games updated:', games);
      });

      newSocket.on('disconnect', () => {
        console.log('Disconnected from server');
      });

      return () => {
        newSocket.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = document.cookie.split('token=')[1];
        if (!token) {
          console.log('No token found');
          return;
        }

        const response = await axios.get('https://ablaze-tar-grasshopper.glitch.me/user', {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data.success) {
          setIDplayer(response.data.user.IDplayer);
          /*console.log(IDplayer)*/
         // setUserName(response.data.user.name); // Set the userName state
          /*console.log(userName)//
        }
      } catch (err) {
        console.error('Failed to fetch user details:', err);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <SocketContext.Provider value={{ socket, games, userName, setGames, userId, IDplayer }}>
      {children}
    </SocketContext.Provider>
  );
};


import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import axios from 'axios';

const SOCKET_SERVER_URL = 'https://ablaze-tar-grasshopper.glitch.me'; // Your Glitch project URL

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [games, setGames] = useState([]);
  const [userId, setUserId] = useState('');
  const [IDplayer, setIDplayer] = useState('');
  const [userName, setUserName] = useState('');

  useEffect(() => {
    //const newSocket = io(SOCKET_SERVER_URL, { transports: ['websocket']
  
  });
  //
  const token = document.cookie.split('token=')[1];
    const newSocket = io(SOCKET_SERVER_URL, {
      transports: ['websocket'],
      extraHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Connected to server');
    });

    newSocket.on('user id', (id) => {
      setUserId(id);
      console.log('socket id', id);
    });

    newSocket.on('update games', (games) => {
      setGames(games);
      console.log('Games updated:', games);
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  //useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = document.cookie.split('token=')[1];
        console.log('Token:', token);

        const response = await axios.get('https://ablaze-tar-grasshopper.glitch.me/user', {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('User details response:', response.data);

        if (response.data.success) {
          setIDplayer(response.data.user.IDplayer);
          console.log('IDplayer:', response.data.user.IDplayer);
        }
      } catch (err) {
        console.error('Failed to fetch user details:', err);
      }
    };

    fetchUserDetails();
  }, []);
  //

  
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = document.cookie.split('token=')[1];
        console.log('Token:', token);

        const response = await axios.get('https://ablaze-tar-grasshopper.glitch.me/user', {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('User details response:', response.data);

        if (response.data.success) {
          setIDplayer(response.data.user.IDplayer);
          setUserName(response.data.user.name); // Set the userName state
          console.log('IDplayer:', response.data.user.IDplayer);
          console.log('UserName:', response.data.user.name);
        }
      } catch (err) {
        console.error('Failed to fetch user details:', err);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <SocketContext.Provider value={{ socket, games, userName, setGames, userId, IDplayer }}>
      {children}
    </SocketContext.Provider>
  );
};*/
