import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSocket } from './socketcont';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import './aftercheckoutstor.css';

import coinpng from './pngs/2473294.png'
import canjecoin from './pngs/12215511.png'
import logox from './pngs/744018x.png'
import logoo from './pngs/744018o.png'

import canjetoken from './pngs/12215511.png'
import wiconlogo from './pngs/6897039.png'

function Aftercheckoutstor() {
    const { socket, games, userName, balance, setGames, userId, IDplayer } = useSocket();
    const navigate = useNavigate();
    const [selectedLottocoins, setSelectedLottocoins] = useState('');
    const [totalCost, setTotalCost] = useState(0);

    const location = useLocation();
    const { quantity } = location.state || { quantity: '' };
    const [showBtcAddress, setShowBtcAddress] = useState(false);
    const [email, setEmail] = useState('');

    const { purchaseStatus } = location.state || { purchaseStatus: null };


    useEffect(() => {
        if (quantity) {
            const cost = calculateTotalCost(quantity);
            setTotalCost(cost);
        }
    }, [quantity]);

    const calculateTotalCost = (quantity) => {
        const multiplier = 1000;
        return (parseInt(quantity, 10) * multiplier).toFixed(2);
    };

    const handlePaymentButtonClick = () => {
        setShowBtcAddress(true);
    };

    const handleLogout = () => {
        const token = document.cookie.split('token=')[1];
        if (token) {
            const decoded = jwtDecode(token);
            console.log('Token:', token);
            console.log('Decoded:', decoded);
            if (decoded && decoded.userId) {
                console.log('Emitting logout event');
                socket.emit('logout', { token }, (response) => {
                    if (response.success) {
                        document.cookie = 'token=; Max-Age=0'; // Delete token
                        navigate('/login'); // Redirect to login page
                    } else {
                        console.error('Logout failed:', response.message);
                    }
                });
            } else {
                console.error('Invalid token');
            }
        } else {
            console.error('No token found');
        }
    };

    const handleBuy = async () => {
        if (quantity) {
            try {
                const response = await axios.post('https://ablaze-tar-grasshopper.glitch.me/sendPurchaseInfoCanje', {
                    IDplayer,
                    email,
                    lottocoins: quantity,
                    status: 'Pending'
                });
                if (response.data.success) {
                    alert('Purchase information sent successfully');
                    navigate('/aftercheckoutstor', { state: { purchaseStatus: 'success' } });
                } else {
                    alert('Error sending purchase information');
                    navigate('/aftercheckoutstor', { state: { purchaseStatus: 'error' } });
                }
            } catch (error) {
                console.error('Error sending purchase information:', error);
                alert('Error sending purchase information');
            }
        } else {
            alert('Please input quantity amount.');
        }
    };

    return (
        <>
        <div className="containerAtor">
            <div className='topmenuPage'>
            <div className='logoName'>
        
        <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
        <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>


        Tic Tac Toe Simple <div className='beta'>BETA</div>
    </div>
                <div className='cuentaPageBalance'><strong>Balance:&nbsp;</strong>
                <div className='mono'> {balance.toFixed(2)}</div></div>
                <div className='cuentaPage'>
                    <button className='logoutButton' onClick={handleLogout}>Logout</button>
                </div>
            </div>
            <div className='secondtopmenuBPage'>
                <div className='logoNameBot'><div className='twomono'>Hello:</div>
                <strong> {userName}</strong></div>
                <Link className='playbuybutton' to='/historybuy'>JUGAR</Link>
                <Link className='buybutton' to='/historybuyct'>HISTORIAL COMPRA</Link>
            </div>
            <div className='mepagePage'>
        <div className='gameplay'>
            <div className='gamelogo'><img src={canjecoin} alt="Coin" /></div>
            <div className='gameplaydiv'>CANJETOKEN</div>

            <div className='aftercheck'>
                    {purchaseStatus === 'success' && <div className='aftercheckdiv'>
                        Purchase was successful
                        <br></br>
                        Status: Pending
                    </div>}
                    {purchaseStatus === 'error' && <div className='aftercheckediv'>Error sending purchase information</div>}
                </div>
                
            
        
        </div>


        <div>
                <div className='bottestComprar'><Link to='/depag' className='buyComprar'>Jugar</Link></div>
                </div>

                <div className='prom'>
            <div className='wicon'><img src={wiconlogo} alt="Coin" /></div>
                <div className='promdiv'>
                    COMPRA REALIZADA. ESPERA UN MAXIMO DE 10 MINUTOS A QUE TU COMPRA SEA CONFIRMADA
                    Y TUS BALANCES SEAN ACTUALIZADOS Y ESTEN DISPONIBLES.
                </div>
                


            </div>
       

                
                
        
      </div>
      
            
        </div>




        <div className="containerAtorSmall">

        <div className='topmenuPage'>
      
            <div className='logoName'>
  {/*<div className='gcoin'><img src={logo} alt="Coin" /></div>*/}
  
             <div className='glogos'>
                <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
  
                <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>
      
            </div>
  
            <div className='namelogo'>
                TICTACTOE SIMPLE
             </div>
      
            <div className='beta'>BETA</div>
        </div>

        </div>




          <div className='topmenuPage'>
  
  
            <div className='cuentaPageBalance'>
                <strong>Balance:&nbsp;</strong> <div className='mono'>{balance.toFixed(2)}</div>
            </div>

            <div className='cuentaPageName'>
                <div className='logoNameBot'><div className='twomono'>Hello:</div> <strong>{userName}</strong></div>
            </div>

            </div>

        <div className='secondtopmenuPage'>

            <div className='buttonscuentaPage'>

  
            <Link className='buybutton' to='/historybuyct'> <strong>HISTORIAL COMPRA</strong></Link>
            <button className='logoutButton' onClick={handleLogout}>Logout</button>
            </div>

        </div>  




        <div className='mepagePage'>
        <div className='gameplay'>
            <div className='gamelogo'><img src={canjecoin} alt="Coin" /></div>
            <div className='gameplaydiv'>CANJETOKEN</div>

            <div className='aftercheck'>
                    {purchaseStatus === 'success' && <div className='aftercheckdiv'>
                        Purchase was successful
                        <br></br>
                        Status: Pending
                    </div>}
                    {purchaseStatus === 'error' && <div className='aftercheckediv'>Error sending purchase information</div>}
                </div>
                
            
        
        </div>


        <div>
                <div className='bottestComprar'><Link to='/depag' className='buyComprar'>Jugar</Link></div>
                </div>

                <div className='prom'>
            <div className='wicon'><img src={wiconlogo} alt="Coin" /></div>
                <div className='promdiv'>
                    COMPRA REALIZADA. ESPERA UN MAXIMO DE 10 MINUTOS A QUE TU COMPRA SEA CONFIRMADA
                    Y TUS BALANCES SEAN ACTUALIZADOS Y ESTEN DISPONIBLES.
                </div>
                


            </div>
        
        
      </div>
        </div>

        </>
    );
}

export default Aftercheckoutstor;
