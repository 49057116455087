import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSocket } from './socketcont';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import './checkoutvende.css';

import coinpng from './pngs/2473294.png'
import canjecoin from './pngs/12215511.png'
import logox from './pngs/744018x.png'
import logoo from './pngs/744018o.png'

function Checkoutvende() {
    const { socket, games, userName, balance, setGames, userId, IDplayer } = useSocket();
    const navigate = useNavigate();
    const [selectedLottocoins, setSelectedLottocoins] = useState('');
    const [totalCost, setTotalCost] = useState(0);

    const location = useLocation();
    const { quantity } = location.state || { quantity: '' };
    const [showBtcAddress, setShowBtcAddress] = useState(false);
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');



    useEffect(() => {
        if (quantity) {
            const cost = calculateTotalCost(quantity);
            setTotalCost(cost);
        }
    }, [quantity]);

    const calculateTotalCost = (quantity) => {
        const multiplier = 700;
        return (parseInt(quantity, 10) * multiplier).toFixed(2);
    };

    const handlePaymentButtonClick = () => {
        setShowBtcAddress(true);
    };

    const handleLogout = () => {
        const token = document.cookie.split('token=')[1];
        if (token) {
            const decoded = jwtDecode(token);
            console.log('Token:', token);
            console.log('Decoded:', decoded);
            if (decoded && decoded.userId) {
                console.log('Emitting logout event');
                socket.emit('logout', { token }, (response) => {
                    if (response.success) {
                        document.cookie = 'token=; Max-Age=0'; // Delete token
                        navigate('/login'); // Redirect to login page
                    } else {
                        console.error('Logout failed:', response.message);
                    }
                });
            } else {
                console.error('Invalid token');
            }
        } else {
            console.error('No token found');
        }
    };

    const handleBuy = async () => {
        if (quantity) {
            try {
                const response = await axios.post('https://ablaze-tar-grasshopper.glitch.me/sendPurchaseInfoSale', {
                    IDplayer,
                    email,
                    address,
                    lottocoins: quantity,
                    status: 'Pending'
                });
                if (response.data.success) {
                    alert('Purchase information sent successfully');
                    navigate('/aftervende', { state: { purchaseStatus: 'success' } });
                } else {
                    alert('Error sending purchase information');
                    navigate('/aftervende', { state: { purchaseStatus: 'error' } });
                }
            } catch (error) {
                console.error('Error sending purchase information:', error);
                alert('Error sending purchase information');
            }
        } else {
            alert('Please input quantity amount.');
        }
    };

    return (
        <>
        <div className="containerCvende">
            <div className='topmenuPage'>
            <div className='logoName'>
        
        <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
        <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>


        Tic Tac Toe Simple <div className='beta'>BETA</div>
    </div>
                <div className='cuentaPageBalance'><strong>
                    Balance:&nbsp;</strong><div className='mono'> {balance.toFixed(2)}</div></div>
                <div className='cuentaPage'>
                    <button className='logoutButton' onClick={handleLogout}>Logout</button>
                </div>
            </div>
            <div className='secondtopmenuBPage'>
                <div className='logoNameBot'>
                    <div className='twomono'>Hello:</div>
                    <strong> {userName}</strong></div>
                <Link className='playbuybutton' to='/depag'>JUGAR</Link>
                <Link className='buybutton' to='/historybuy'>HISTORIAL COMPRA</Link>
            </div>
            <div className='mepagePage'>
        <div className='gameplay'>
            <div className='gamelogo'><img src={canjecoin} alt="Coin" /></div>
            <div className='gameplaydiv'>CANJETOKEN</div>
            
        
        </div>


        <div className='secondsell'>
        <div className='quantity'>
                
                            <div className='qdiv'>
                                <label>Quantity: {quantity}</label>
                                <label>Total: {totalCost}</label>
                            </div>
                                    
                </div>
                <div className='email'>
                
                            <div>
                                <label>Email:</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                                    
                </div>
                <div className='email'>
                
                            <div>
                                <label>Address:</label>
                                <input
                                    type="email"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    required
                                />
                            </div>
                                    
                </div>
                <div className='payment'>
                    Select Payment:<div className='paymentBitcoin'><button onClick={handlePaymentButtonClick} className='paymentBut'>Bitcoin</button></div>
                </div>
                {showBtcAddress && (
                    <div className='btcadress'>
                        <label>COLOCA UN ADDRESS DE BITCOIN PARA RECIBIR EL PAGO</label>
                    </div>
                )}

<div className='bottestComprar'><button className='buyComprar' onClick={handleBuy}>Confirmar Venta</button></div>
</div>    
        
      </div>
      
            
        </div>




        <div className="containerCvendeSmall">

        <div className='topmenuPage'>
      
            <div className='logoName'>
  {/*<div className='gcoin'><img src={logo} alt="Coin" /></div>*/}
  
             <div className='glogos'>
                <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
  
                <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>
      
            </div>
  
            <div className='namelogo'>
                TICTACTOE SIMPLE
             </div>
      
            <div className='beta'>BETA</div>
        </div>

        </div>




          <div className='topmenuPage'>
  
  
            <div className='cuentaPageBalance'>
                <strong>Balance:&nbsp;</strong> <div className='mono'>{balance.toFixed(2)}</div>
            </div>

            <div className='cuentaPageName'>
                <div className='logoNameBot'><div className='twomono'>Hello:</div> <strong>{userName}</strong></div>
            </div>

            </div>

        <div className='secondtopmenuPage'>

            <div className='buttonscuentaPage'>

  
            <Link className='buybutton' to='/historybuy'> <strong>HISTORIAL COMPRA</strong></Link>
            <button className='logoutButton' onClick={handleLogout}>Logout</button>
            </div>

        </div>  



                    
        <div className='mepagePage'>
        <div className='gameplay'>
            <div className='gamelogo'><img src={canjecoin} alt="Coin" /></div>
            <div className='gameplaydiv'>CANJETOKEN</div>
            
        
        </div>
        <div className='secondsell'>
        <div className='quantity'>
                
                            <div className='qdiv'>
                                <label>Quantity: {quantity}</label>
                                <label>Total: {totalCost}</label>
                                
                            </div>
                                    
                </div>

                <div className='email'>
                
                            <div>
                                <label>Email:</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                                    
                </div>
                <div className='email'>
                
                            <div>
                                <label>Address:</label>
                                <input
                                    type="email"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    required
                                />
                            </div>
                                    
                </div>
                <div className='payment'>
                    Select Payment:<div className='paymentBitcoin'><button onClick={handlePaymentButtonClick} className='paymentBut'>Bitcoin</button></div>
                </div>
                {showBtcAddress && (
                    <div className='btcadress'>
                        <label>COLOCA UN ADDRESS DE BITCOIN PARA RECIBIR EL PAGO</label>
                    </div>
                )}
                <div className='bottestComprar'><button className='buyComprar' onClick={handleBuy}>Confirmar Venta</button></div>
                </div>
      </div>
      
        </div>

        </>
    );
}

export default Checkoutvende;
