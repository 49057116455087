import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SocketProvider } from './socketcont';
import MainPage from './mainpage';
import Page from './page';
import Game from './game';
import Login from './login';
import Create from './create';
import Accountpage from './accountpage';


import Buycoins from './buycoins';
import Checkout from './checkout';
import Aftercheckout from './aftercheckout';
import Historybuy from './historybuy';
import Simplejuego from './simplejuego';
import Stor from './stor';
import Bstor from './beforestorcheckout';
import Checkoutstor from './checkoutstor';
import Aftercheckoutstor from './aftercheckoutstor';


import Vendecanje from './vendecanje';
import Checkoutvende from './checkoutvende';
import Aftervende from './aftervende';

import Profile from './profile';

import Historybuyct from './historybuyct';
import Historysellct from './historysellct';
import Historygame from './historygame';

function App() {
  return (
    <SocketProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/main' element={<MainPage />} />
          <Route path='/page' element={<Page />} />
          <Route path='/game/:gameId' element={<Game />} />
          <Route path='/login' element={<Login />} />
          <Route path='/create' element={<Create />} />
          <Route path='/accountpage' element={<Accountpage />} />

          <Route path='/buycoins' element={<Buycoins />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/aftercheckout' element={<Aftercheckout />} />
          <Route path='/historybuy' element={<Historybuy />} />
          <Route path='/' element={<Simplejuego />} />
          <Route path='/stor' element={<Stor />} />
          <Route path='/bstor' element={<Bstor />} />
          <Route path='/checkoutstor' element={<Checkoutstor />} />
          <Route path='/aftercheckoutstor' element={<Aftercheckoutstor />} />
          <Route path='/bvende' element={<Vendecanje />} />
          <Route path='/checkoutvende' element={<Checkoutvende />} />
          <Route path='/aftervende' element={<Aftervende />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/historybuyct' element={<Historybuyct />} />
          <Route path='/historysellct' element={<Historysellct />} />
          <Route path='/historygame' element={<Historygame />} />
        </Routes>
      </BrowserRouter>
    </SocketProvider>
  );
}

export default App;
