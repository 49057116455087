import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './login.css';

function Login() {
    const [values, setValues] = useState({
        email: '',
        password: ''
    });

    const navigate = useNavigate();
    axios.defaults.withCredentials = true;

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('https://ablaze-tar-grasshopper.glitch.me/login', values, { withCredentials: true })
            .then(res => {
                console.log('Server Response:', res.data);
                if (res.data.success) {
                    document.cookie = `token=${res.data.token}; path=/; Secure; SameSite=None;`;
                    console.log('Logged in');
                    navigate('/accountpage');
                } else {
                    alert(res.data.message);
                }
            })
            .catch(err => console.log(err));
    };

    return (
        <div className='containerLog'>
            <div className='bomenubarLogin'>
                <div className='menubarLogin'>
                    Entra Cuenta
                </div>
            </div>
            <div className='login-formLogin'>
                <h2>Entrar</h2>
                <form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                        <div><label htmlFor='email'><strong>Correo</strong></label></div>
                        <div><input type='text' placeholder='Enter Email' name='email' onChange={e => setValues({...values, email: e.target.value})} className='form-control rounded-0'/></div>
                    </div>
                    <div className='mb-3'>
                        <div><label htmlFor='password'><strong>Contrasena</strong></label></div>
                        <div><input type='password' placeholder='Enter Password' name='password' onChange={e => setValues({...values, password: e.target.value})} className='form-control rounded-0'/></div>
                    </div>
                    <button type='submit' className='btn btn-success w-100 rounded-0'>Entrar</button>
                    <Link to="/register" className='createButton'>O Crea Cuenta</Link>
                </form>
            </div>
        </div>
    );
}

export default Login;
