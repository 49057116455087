import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Link, useNavigate } from 'react-router-dom';
import './mainpage.css'

const SOCKET_SERVER_URL = 'https://ablaze-tar-grasshopper.glitch.me'; // Your Glitch project URL

function App() {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const newSocket = io(SOCKET_SERVER_URL, { transports: ['websocket'] });
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Connected to server');
    });

    newSocket.on('chat message', (msg) => {
      console.log('Received message:', msg);
      setMessages((prev) => [...prev, msg]);
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const handleSendMessage = () => {
    if (socket) {
      console.log('Sending message:', message);
      socket.emit('chat message', message);
      setMessage('');
    }
  };

  return (
    <div className="App">

      <header className="App-header">
        <h1>Chat App</h1>
        <div>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button onClick={handleSendMessage}>
            Send
          </button>
        </div>
        <div>
          <h2>Messages:</h2>
          <ul>
            {messages.map((msg, index) => (
              <li key={index}>{msg}</li>
            ))}
          </ul>
        </div>
      </header>

      <div className='button'>
        <Link to='/page' className='buttonLink'>page</Link>
      </div>
    </div>
  );
}

export default App;



/*import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

const SOCKET_SERVER_URL = 'https://ablaze-tar-grasshopper.glitch.me'; // Your Glitch project URL

function App() {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const socket = io(SOCKET_SERVER_URL);

  useEffect(() => {
    // Listen for chat messages from the server
    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('chat message', (msg) => {
      console.log('Received message:', msg);
      setMessages((prev) => [...prev, msg]);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    // Clean up the effect when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  const handleSendMessage = () => {
    // Emit the chat message to the server
    console.log('Sending message:', message);
    socket.emit('chat message', message);
    setMessage('');
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Chat App</h1>
        <div>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button onClick={handleSendMessage}>
            Send
          </button>
        </div>
        <div>
          <h2>Messages:</h2>
          <ul>
            {messages.map((msg, index) => (
              <li key={index}>{msg}</li>
            ))}
          </ul>
        </div>
      </header>
    </div>
  );
}

export default App;
*/