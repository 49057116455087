import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSocket } from './socketcont';
import './simplejuego.css';


import simplelogo from './pngs/simplelogo.png'
import gamelogo from './pngs/gamelogo.png'

import plinkologo from './pngs/Plinko.png'
function Simplejuego() {
  const { socket, games, setGames, userId } = useSocket();
  const navigate = useNavigate();

  /*useEffect(() => {
    if (!socket) return;

    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('update games', (games) => {
      setGames(games);
      console.log('Games updated:', games);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      socket.off('connect');
      socket.off('update games');
      socket.off('disconnect');
    };
  }, [socket, setGames]);

  const handleJoinGame = (gameId) => {
    if (socket) {
      socket.emit('join game', gameId, (response) => {
        if (response.success) {
          console.log(`Joined game ${gameId}`);
          navigate(`/game/${gameId}`);
        }
      });
    }
  };*/

  const handleJoinGame = (gameId) => {
    if (socket) {
      socket.emit('join game', gameId, (response) => {
        if (response.success) {
          console.log(`Joined game ${gameId}`);
          navigate(`/game/${gameId}`);
        } else {
          console.log(`Failed to join game ${gameId}: ${response.message}`);
        }
      });
    }
  };

  const handleViewGame = (gameId) => {
    
          navigate(`/game/${gameId}`);
        
  };

  return (
    <>
    <div className="containerSimple">

        

      <div className='topmenuPage'>
        <div className='logoName'>
            <div className='gcoin'><img src={simplelogo} alt="Coin" /></div>
            <div className='simplelogo'>JUEGOSIMPLE</div>
            <div className='beta'>BETA</div>
        </div>
        {/*<div className='cuentaPage'>{userId}</div>*/}
        <div className='cuentaPage'>
            <Link className='createButtonPage' to='/create'>Crear cuenta</Link>
            <Link className='loginButtonPage' to='/login'>Entrar</Link>
        </div>

      </div>

      <div className='mepagePage'>
        <div className='gameplay'>
            <div className='gamelogo'><img src={gamelogo} alt="Coin" /></div>
            <div className='gameplaydiv'>TICTACTOE</div>
            <div className='gameplaydiv'>SIMPLE</div>
        <Link className='playButton' to='/page'>Jugar</Link>
        </div>

        <div className='gameplay'>
            <div className='gamelogo'><img src={plinkologo} alt="Coin" /></div>
            <div className='gameplaydiv'>PLINKO</div>
            <div className='gameplaydiv'>SIMPLE</div>
        <Link className='playButtonPlinko' to='/page' onClick={(e) => e.preventDefault()}>PRONTO</Link>

        
        </div>
        
      </div>
    </div>


    <div className="containerSimpleSmall">

        

      <div className='topmenuPage'>
        <div className='logoName'>
            <div className='gcoin'><img src={simplelogo} alt="Coin" /></div>
            <div className='simplelogo'>JUEGOSIMPLE</div>
            <div className='beta'>BETA</div>
        </div>
        {/*<div className='cuentaPage'>{userId}</div>*/}
        

      </div>
      <div className='secondtopmenuPage'>
        <div className='cuentaPage'>
        <Link className='createButtonPage' to='/create'>Crear cuenta</Link>
        <Link className='loginButtonPage' to='/login'>Entrar</Link>
        </div>
      </div>

      <div className='mepagePage'>
        <div className='gameplay'>
            <div className='gamelogo'><img src={gamelogo} alt="Coin" /></div>
            <div className='gameplaydiv'>TICTACTOE</div>
            <div className='gameplaydiv'>SIMPLE</div>
        <Link className='playButton' to='/page'>Jugar</Link>

        
        </div>

        <div className='gameplay'>
            <div className='gamelogo'><img src={plinkologo} alt="Coin" /></div>
            <div className='gameplaydiv'>PLINKO</div>
            <div className='gameplaydiv'>SIMPLE</div>
        <Link className='playButtonPlinko' to='/page' onClick={(e) => e.preventDefault()}>PRONTO</Link>

        
        </div>
        
      </div>
    </div>

    </>
  );
}



/*<button className='joGameButton' onClick={() => handleJoinGame(game.id)}>
                Play
              </button>
 Add auth to login.
 role: Player to players so that
 they can only button

              */

export default Simplejuego;
