import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSocket } from './socketcont';
import { jwtDecode } from 'jwt-decode';
import './buycoins.css';

import coinpng from './pngs/2473294.png'
import logox from './pngs/744018x.png'
import logoo from './pngs/744018o.png'

import wiconlogo from './pngs/6897039.png'

function Buycoins() {
    const { socket, games, userName, balance, setGames, userId, IDplayer } = useSocket();
    const navigate = useNavigate();
    const [selectedLottocoins, setSelectedLottocoins] = useState('');

    const handleLogout = () => {
        const token = document.cookie.split('token=')[1];
        if (token) {
            const decoded = jwtDecode(token);
            console.log('Token:', token);
            console.log('Decoded:', decoded);
            if (decoded && decoded.userId) {
                console.log('Emitting logout event');
                socket.emit('logout', { token }, (response) => {
                    if (response.success) {
                        document.cookie = 'token=; Max-Age=0'; // Delete token
                        navigate('/login'); // Redirect to login page
                    } else {
                        console.error('Logout failed:', response.message);
                    }
                });
            } else {
                console.error('Invalid token');
            }
        } else {
            console.error('No token found');
        }
    };

    const handleBuy = () => {
        if (selectedLottocoins) {
            navigate('/checkout', { state: { selectedLottocoins } });
        } else {
            alert('Please select a Lottocoins amount.');
        }
    };

    return (
        <>
        <div className="containerBPage">
            <div className='topmenuPage'>
            <div className='logoName'>
        
        <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
        <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>


        Tic Tac Toe Simple <div className='beta'>BETA</div>
    </div>
                <div className='cuentaPageBalance'><strong>Balance:&nbsp;</strong>
                <div className='mono'> {balance.toFixed(2)}</div></div>
                <div className='cuentaPage'>
                    <button className='logoutButton' onClick={handleLogout}>Logout</button>
                </div>
            </div>
            <div className='secondtopmenuBPage'>
                <div className='logoNameBot'><div className='twomono'>
                    Hello: </div>
                    <strong> {userName}</strong></div>
                <Link className='buybutton' to='/historybuy'>HISTORIAL COMPRA</Link>
            </div>
            <div className='eggdiv'>
                <div className='buytitle'>COMPRA <div className='gcoin'><img src={coinpng} alt="Coin" /></div>SIMPLETOKENS</div>
                <div className='buyinfo'>100 SIMPLETOKENS = $ 1 USD</div>
                <div className='buycontent'>
                    <div className='buycontentlist'>
                        <div className='radiobutton'>
                            <input
                                type="radio"
                                name="lottocoins"
                                value="100"
                                onChange={() => setSelectedLottocoins('100')}
                                checked={selectedLottocoins === '100'}
                            />
                        </div>
                        <label htmlFor="10">100 &nbsp;SIMPLETOKENS &nbsp;$ 1 USD</label>
                    </div>
                    <div className='buycontentlist'>
                        <div className='radiobutton'>
                            <input
                                type="radio"
                                name="lottocoins"
                                value="100"
                                onChange={() => setSelectedLottocoins('1000')}
                                checked={selectedLottocoins === '1000'}
                            />
                        </div>
                        <label htmlFor="100">1000 SIMPLETOKENS &nbsp;$ 10 USD</label>
                    </div>
                    <div className='buycontentlist'>
                        <div className='radiobutton'>
                            <input
                                type="radio"
                                name="lottocoins"
                                value="1000"
                                onChange={() => setSelectedLottocoins('2000')}
                                checked={selectedLottocoins === '2000'}
                            />
                        </div>
                        <label htmlFor="1000">2000 SIMPLETOKENS &nbsp;$ 20 USD</label>
                    </div>
                    <div className='buycontentlist'>
                        <div className='radiobutton'>
                            <input
                                type="radio"
                                name="lottocoins"
                                value="other"
                                
                                onChange={(e) => setSelectedLottocoins(e.target.value)}
                                checked={!['10', '100', '1000'].includes(selectedLottocoins)}
                                
                            />
                        </div>
                        <label htmlFor="other">Otra cantidad:</label>
                        <input
                            className='inputbetComprar'
                            type="text"
                            value={selectedLottocoins}
                            onFocus={(e) => e.target.value = ''}
                            onChange={(e) => setSelectedLottocoins(e.target.value)}
                        />
                    </div>
                </div>
                <div className='bottestComprar'><button className='buyComprar' onClick={handleBuy}>Comprar</button></div>
            
                
            </div>

            <div className='prom'>
            <div className='wicon'><img src={wiconlogo} alt="Coin" /></div>
                <div className='promdiv'>
                    COMPRA SIMPLETOKENS PARA JUGAR TIC TAC TOE Y COMPRAR CANJETOKENS.
                </div>
                


            </div>
        </div>




        <div className="containerBPageSmall">

        <div className='topmenuPage'>
      
            <div className='logoName'>
  {/*<div className='gcoin'><img src={logo} alt="Coin" /></div>*/}
  
             <div className='glogos'>
                <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
  
                <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>
      
            </div>
  
            <div className='namelogo'>
                TICTACTOE SIMPLE
             </div>
      
            <div className='beta'>BETA</div>
        </div>

        </div>




          <div className='topmenuPage'>
  
  
            <div className='cuentaPageBalance'>
                <strong>Balance:&nbsp;</strong> <div className='mono'>{balance.toFixed(2)}</div>
            </div>

            <div className='cuentaPageName'>
                <div className='logoNameBot'><div className='twomono'>Hello:</div> <strong>{userName}</strong></div>
            </div>

            </div>

        <div className='secondtopmenuPage'>

            <div className='buttonscuentaPage'>

  
            <Link className='buybutton' to='/historybuy'> <strong>HISTORIAL COMPRA</strong></Link>
            <button className='logoutButton' onClick={handleLogout}>Logout</button>
            </div>

        </div>  




            <div className='eggdiv'>
                <div className='buytitle'>COMPRA <div className='gcoin'><img src={coinpng} alt="Coin" /></div>SIMPLETOKENS</div>
                <div className='buyinfo'>100 SIMPLETOKENS = $ 1 USD</div>
                <div className='buycontent'>
                    <div className='buycontentlist'>
                        <div className='radiobutton'>
                            <input
                                type="radio"
                                name="lottocoins"
                                value="100"
                                onChange={() => setSelectedLottocoins('100')}
                                checked={selectedLottocoins === '100'}
                            />
                        </div>
                        <label htmlFor="10">100 SIMPLETOKENS &nbsp;&nbsp;$ 1 USD</label>
                    </div>
                    <div className='buycontentlist'>
                        <div className='radiobutton'>
                            <input
                                type="radio"
                                name="lottocoins"
                                value="100"
                                onChange={() => setSelectedLottocoins('1000')}
                                checked={selectedLottocoins === '1000'}
                            />
                        </div>
                        <label htmlFor="100">1000 SIMPLETOKENS &nbsp;$ 10 USD</label>
                    </div>
                    <div className='buycontentlist'>
                        <div className='radiobutton'>
                            <input
                                type="radio"
                                name="lottocoins"
                                value="1000"
                                onChange={() => setSelectedLottocoins('2000')}
                                checked={selectedLottocoins === '2000'}
                            />
                        </div>
                        <label htmlFor="1000">2000 SIMPLETOKENS &nbsp;$ 20 USD</label>
                    </div>
                    <div className='buycontentlist'>
                        <div className='radiobutton'>
                            <input
                                type="radio"
                                name="lottocoins"
                                value="other"
                                
                                onChange={(e) => setSelectedLottocoins(e.target.value)}
                                checked={!['10', '100', '1000'].includes(selectedLottocoins)}
                                
                            />
                        </div>
                        <label htmlFor="other">Otra cantidad:</label>
                        <input
                            className='inputbetComprar'
                            type="text"
                            value={selectedLottocoins}
                            onFocus={(e) => e.target.value = ''}
                            onChange={(e) => setSelectedLottocoins(e.target.value)}
                        />
                    </div>
                </div>
                <div className='bottestComprar'><button className='buyComprar' onClick={handleBuy}>Comprar</button></div>
            </div>

            <div className='prom'>
            <div className='wicon'><img src={wiconlogo} alt="Coin" /></div>
                <div className='promdiv'>
                    COMPRA SIMPLETOKENS PARA JUGAR TIC TAC TOE Y COMPRAR CANJETOKENS.
                </div>
                


            </div>
        </div>

        </>
    );
}

export default Buycoins;
