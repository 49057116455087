import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSocket } from './socketcont';
import './page.css';


import canjetoken from './pngs/12215511.png'
import logo from './pngs/7440181.png'
import logox from './pngs/744018x.png'
import logoo from './pngs/744018o.png'
import wiconlogo from './pngs/6897039.png'

function Page() {
  const { socket, games, setGames, userId } = useSocket();
  const navigate = useNavigate();

  /*useEffect(() => {
    if (!socket) return;

    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('update games', (games) => {
      setGames(games);
      console.log('Games updated:', games);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      socket.off('connect');
      socket.off('update games');
      socket.off('disconnect');
    };
  }, [socket, setGames]);

  const handleJoinGame = (gameId) => {
    if (socket) {
      socket.emit('join game', gameId, (response) => {
        if (response.success) {
          console.log(`Joined game ${gameId}`);
          navigate(`/game/${gameId}`);
        }
      });
    }
  };*/

  const handleJoinGame = (gameId) => {
    if (socket) {
      socket.emit('join game', gameId, (response) => {
        if (response.success) {
          console.log(`Joined game ${gameId}`);
          navigate(`/game/${gameId}`);
        } else {
          console.log(`Failed to join game ${gameId}: ${response.message}`);
        }
      });
    }
  };

  const handleViewGame = (gameId) => {
    
          navigate(`/game/${gameId}`);
        
  };

  return (
    <>
    <div className="containerPage">
    
    

      <div className='topmenuPage'>
        <div className='logoName'>
        {/*<div className='gcoin'><img src={logo} alt="Coin" /></div>*/}
        <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
        
        <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>

        
            Tic Tac Toe Simple <div className='beta'>BETA</div>
        </div>
        {/*<div className='cuentaPage'>{userId}</div>*/}
        <div className='cuentaPage'>
            <Link className='createButtonPage' to='/create'>Crear cuenta</Link>
            <Link className='loginButtonPage' to='/login'>Entrar</Link>
        </div>

      </div>

      <div className='mepagePage'>

        <div className='toprow'>
            <div className='gamePage'>Create Game</div>
            
            <div className='divprom'></div>
            
            <div className='prom'>
            <div className='wicon'><img src={wiconlogo} alt="Coin" /></div>
                <div className='promdiv'>
                    1. JUEGA
                </div>
                <div className='promdiv'>
                    2. GANA
                </div>
                <div className='promdivbot'>
                    <div className='vende'>VENDE TUS</div>
                    
                    <div className='promdivbotbot'>
                    <div className='gcoin'><img src={canjetoken} alt="Coin" /></div>
                     <div className='vende'>CANJETOKENS!</div>
                    </div>
                </div>


            </div>
        {/*<div className='gamePage'>Create Game</div>*/}
        </div>


        <div className='mepagePageTitle'>
          {'>'}<div className='gamecoin'>1</div> coin games:
        </div>

        <div className="gameMenuPage">
          {games.filter(game => !game.ended).map((game) => (
            <div className='gamePage' key={game.id}>
              <div className='gameidPage'>Game {game.id.substring(0, 3)}</div>
              <div className='playercountPage'>Players: {game.players.length}/2</div>
              <button className='joGameButton' onClick={() => handleViewGame(game.id)}>
                View
              </button>
              
            </div>
          ))}
        </div>
      </div>

      
    </div>

    {/*Can I actually from container*/}

<div className="containerPageSmall">
    
    

<div className='topmenuPage'>
  <div className='logoName'>
  {/*<div className='gcoin'><img src={logo} alt="Coin" /></div>*/}
  
        <div className='glogos'>
        <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
  
        <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>
        </div>
  
      <div className='namelogo'>
      TICTACTOE SIMPLE
        </div>
      
      <div className='beta'>BETA</div>
  </div>

  {/*<div className='cuentaPage'>
      <Link className='createButtonPage' to='/create'>Crear cuenta</Link>
      <Link className='loginButtonPage' to='/login'>Entrar</Link>
          </div>*/}
  
  {/*<div className='cuentaPage'>{userId}</div>*/}
  

</div>
<div className='secondtopmenuPage'>
<div className='cuentaPage'>
      <Link className='createButtonPage' to='/create'>Crear cuenta</Link>
      <Link className='loginButtonPage' to='/login'>Entrar</Link>
  </div>
      </div>


<div className='mepagePage'>

        <div className='prom'>
        {/*<div className='wicon'><img src={wiconlogo} alt="Coin" /></div>*/}
            <div className='promdiv'>
                1. JUEGA
            </div>
            <div className='promdiv'>
                2. GANA
            </div>
            <div className='promdivbot'>
            <div className='vende'>VENDE TUS</div>
    
            <div className='promdivbotbot'>
            <div className='gcoin'><img src={canjetoken} alt="Coin" /></div>
            <div className='vende'>CANJETOKENS!</div>
            </div>
        </div>


</div>

  <div className='toprow'>

      <div className='gamePage'>Create Game</div>
      
      <div className='divprom'></div>
      
      
  {/*<div className='gamePage'>Create Game</div>*/}
  </div>


  <div className='mepagePageTitle'>
    {'>'}<div className='gamecoin'>1</div> coin games:
  </div>

  <div className="gameMenuPage">
    {games.filter(game => !game.ended).map((game) => (
      <div className='gamePage' key={game.id}>
        <div className='gameidPage'>Game {game.id.substring(0, 3)}</div>
        <div className='playercountPage'>Players: {game.players.length}/2</div>
        <button className='joGameButton' onClick={() => handleViewGame(game.id)}>
          View
        </button>
        
      </div>
    ))}
  </div>
</div>


</div>

</>

    //*Can I actually from container*/

    





  );
}

/*<button className='joGameButton' onClick={() => handleJoinGame(game.id)}>
                Play
              </button>
 Add auth to login.
 role: Player to players so that
 they can only button

              */

export default Page;
