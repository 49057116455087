import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSocket } from './socketcont';
import { jwtDecode } from 'jwt-decode';
import './profile.css';

import coinpng from './pngs/2473294.png'
import logox from './pngs/744018x.png'
import logoo from './pngs/744018o.png'
import icon from './pngs/person.png'

function Bvende() {
    const { socket, games, ctbalance, wins, los, userName, balance, setGames, userId, IDplayer } = useSocket();
    const navigate = useNavigate();
    const [selectedLottocoins, setSelectedLottocoins] = useState('');

    const [quantity, setQuantity] = useState('');

    const handleLogout = () => {
        const token = document.cookie.split('token=')[1];
        if (token) {
            const decoded = jwtDecode(token);
            console.log('Token:', token);
            console.log('Decoded:', decoded);
            if (decoded && decoded.userId) {
                console.log('Emitting logout event');
                socket.emit('logout', { token }, (response) => {
                    if (response.success) {
                        document.cookie = 'token=; Max-Age=0'; // Delete token
                        navigate('/login'); // Redirect to login page
                    } else {
                        console.error('Logout failed:', response.message);
                    }
                });
            } else {
                console.error('Invalid token');
            }
        } else {
            console.error('No token found');
        }
    };

    const handleBuy = () => {
        if (quantity) {
            console.log(quantity)
            navigate('/checkoutvende', { state: { quantity } });
        } else {
            alert('Please input quantity amount.');
        }
    };
     const totalGames = wins + los;
    const winRate = totalGames > 0 ? (wins / totalGames) * 100 : 0;

    return (
        <>
        <div className="containerProfile">
            <div className='topmenuPage'>

            <div className='logoName'>
        
                <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
                <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>

        
                Tic Tac Toe Simple <div className='beta'>BETA</div>
            </div>
                
                <div className='cuentaPageBalance'><strong>Balance:&nbsp;</strong><div className='mono'> {balance.toFixed(2)}</div></div>
                <div className='cuentaPage'>
                    <button className='logoutButton' onClick={handleLogout}>Logout</button>
                </div>
            </div>
            <div className='secondtopmenuBPage'>
                <div className='logoNameBot'>
                    
                <div className='twomono'>
                    Hello: </div>
                     <strong>{userName}</strong>
                
                
                </div>
                <Link className='playbuybutton' to='/depag'>JUGAR</Link>
                <Link className='buybutton' to='/historybuy'>HISTORIAL COMPRA</Link>
            </div>


            <div className='mepagePage'>
       
       <div className='leftmepagePage'>
            <div className='divbalances'>
            <div className='cuentaPageBalance'><strong>Balance:&nbsp;</strong><div className='mono'> {balance.toFixed(2)}</div></div>
            <div className='cuentaPageBalance'><strong>CANJETOKENS:&nbsp;</strong><div className='mono'> {ctbalance.toFixed(2)}</div></div>
            
            </div>

            <div className='divwinrate'>
            <div className='colwinrate'>
            <div className='winrate'>Wins: {wins}</div>
            <div className='winrate'>Los: {los}</div>
            </div>
            <div className='winrate'>
        <strong>Win Rate:&nbsp;</strong>
        <div className='mono'>{winRate.toFixed(2)}%</div>
      </div>

            </div>

            </div>

            <div className='divbuttons'>
            <Link className='divbuybutton' to='/historybuy'>HISTORIAL COMPRA SIMPLECOINS</Link>
            <Link className='divbuybutton' to='/historybuyct'>HISTORIAL COMPRA CANJETOKENS</Link>
            <Link className='divbuybutton' to='/historysellct'>HISTORIAL VENTA CANJETOKENS</Link>
            <Link className='divbuybutton' to='/historygame'>HISTORIAL JUEGOS</Link>
            </div>
                
        
      </div>
      
            
        </div>




        <div className="containerProfileSmall">

        <div className='topmenuPage'>
      
            <div className='logoName'>
  
  
             <div className='glogos'>
                <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
  
                <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>
      
            </div>
  
            <div className='namelogo'>
                TICTACTOE SIMPLE
             </div>
      
            <div className='beta'>BETA</div>
        </div>

        </div>




          <div className='topmenuPage'>
  
  
            <div className='cuentaPageBalance'>
                <strong>Balance:&nbsp;</strong> <div className='mono'>{balance.toFixed(2)}</div>
            </div>

            <div className='cuentaPageName'>
                <div className='logoNameBot'><div className='twomono'>Hello:</div> <strong>{userName}</strong></div>
            </div>

            </div>

        <div className='secondtopmenuPage'>

            <div className='buttonscuentaPage'>

  
            <Link className='playbuybutton' to='/depag'>JUGAR</Link>
            <button className='logoutButton' onClick={handleLogout}>Logout</button>
            </div>

        </div>  

        <div className='mepagePage'>
            
            
        <div className='divbalances'>
            <div className='cuentaPageBalance'><strong>Balance:&nbsp;</strong><div className='mono'> {balance.toFixed(2)}</div></div>
            <div className='cuentaPageBalance'><strong>CANJETOKENS:&nbsp;</strong><div className='mono'> {ctbalance.toFixed(2)}</div></div>
            
            </div>

            <div className='divwinrate'>
            <div className='winrate'>Wins: <strong>{wins}</strong></div>
            <div className='winrate'>Los: <strong>{los}</strong></div>

            <div className='winrate'>
        <strong>Win Rate:&nbsp;</strong>
        <div className='mono'>{winRate.toFixed(2)}%</div>
      </div>

            </div>

            

            <div className='divbuttons'>
            <Link className='divbuybutton' to='/historybuy'>HISTORIAL COMPRA SIMPLECOINS</Link>
            <Link className='divbuybutton' to='/historybuyct'>HISTORIAL COMPRA CANJETOKENS</Link>
            <Link className='divbuybutton' to='/historysellct'>HISTORIAL VENTA CANJETOKENS</Link>
            <Link className='divbuybutton' to='/historygame'>HISTORIAL JUEGOS</Link>
            </div>
                
        
      </div>


        
        </div>

        </>
    );
}

export default Bvende;
