import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSocket } from './socketcont';
import { jwtDecode } from 'jwt-decode';
import './beforestorcheckout.css';

import coinpng from './pngs/2473294.png'
import canjecoin from './pngs/12215511.png'
import logox from './pngs/744018x.png'
import logoo from './pngs/744018o.png'

import canjetoken from './pngs/12215511.png'
import wiconlogo from './pngs/6897039.png'

function Bstor() {
    const { socket, games, userName, balance, setGames, userId, IDplayer } = useSocket();
    const navigate = useNavigate();
    const [selectedLottocoins, setSelectedLottocoins] = useState('');

    const [quantity, setQuantity] = useState('');

    const handleLogout = () => {
        const token = document.cookie.split('token=')[1];
        if (token) {
            const decoded = jwtDecode(token);
            console.log('Token:', token);
            console.log('Decoded:', decoded);
            if (decoded && decoded.userId) {
                console.log('Emitting logout event');
                socket.emit('logout', { token }, (response) => {
                    if (response.success) {
                        document.cookie = 'token=; Max-Age=0'; // Delete token
                        navigate('/login'); // Redirect to login page
                    } else {
                        console.error('Logout failed:', response.message);
                    }
                });
            } else {
                console.error('Invalid token');
            }
        } else {
            console.error('No token found');
        }
    };

    const handleBuy = () => {
        if (quantity) {
            console.log(quantity)
            navigate('/checkoutstor', { state: { quantity } });
        } else {
            alert('Please input quantity amount.');
        }
    };

    return (
        <>
        <div className="containerBtor">
            <div className='topmenuPage'>
            <div className='logoName'>
        
        <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
        <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>


        Tic Tac Toe Simple <div className='beta'>BETA</div>
    </div>
        
        <div className='cuentaPageBalance'><strong>Balance:&nbsp;</strong><div className='mono'> {balance.toFixed(2)}</div></div>
                <div className='cuentaPage'>
                    <button className='logoutButton' onClick={handleLogout}>Logout</button>
                </div>
            </div>
            <div className='secondtopmenuBPage'>
                <div className='logoNameBot'>
                <div className='twomono'>
                    Hello: </div>
                    <strong>{userName}</strong>
                    </div>
                <Link className='playbuybutton' to='/historybuy'>JUGAR</Link>
                <Link className='buybutton' to='/historybuy'>HISTORIAL COMPRA</Link>
            </div>
            <div className='mepagePage'>
        <div className='gameplay'>
            <div className='gamelogo'><img src={canjecoin} alt="Coin" /></div>
            <div className='gameplaydiv'>CANJETOKEN</div>
            
        
        </div>


        <div className='secondsell'>
        <div className='quantity'>
                
                            <div>
                                <label>Quantity:</label>
                                <input
                                    type="text"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    required
                                />
                            </div>
                                    
                </div>

                <button className='playButton' onClick={handleBuy}>COMPRAR</button>
                
                </div>
      </div>

      <div className='prom'>
            <div className='wicon'><img src={wiconlogo} alt="Coin" /></div>
                <div className='promdiv'>
                    LOS CANJETOKENS SON UNA MONEDA COLECCIONABLE EN VENTA QUE PUEDE SER 
                     CANJEADA POR CRIPTOMONEDAS.
                </div>
                


            </div>
      
            
        </div>




        <div className="containerBtorSmall">

        <div className='topmenuPage'>
      
            <div className='logoName'>
  {/*<div className='gcoin'><img src={logo} alt="Coin" /></div>*/}
  
             <div className='glogos'>
                <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
  
                <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>
      
            </div>
  
            <div className='namelogo'>
                TICTACTOE SIMPLE
             </div>
      
            <div className='beta'>BETA</div>
        </div>

        </div>




          <div className='topmenuPage'>
  
  
            <div className='cuentaPageBalance'>
                <strong>Balance:&nbsp;</strong> <div className='mono'>{balance.toFixed(2)}</div>
            </div>

            <div className='cuentaPageName'>
                <div className='logoNameBot'><div className='twomono'>Hello:</div> <strong>{userName}</strong></div>
            </div>

            </div>

        <div className='secondtopmenuPage'>

            <div className='buttonscuentaPage'>

  
            <Link className='buybutton' to='/historybuy'> <strong>HISTORIAL COMPRA</strong></Link>
            <button className='logoutButton' onClick={handleLogout}>Logout</button>
            </div>

        </div>  

        <div className='mepagePage'>
        <div className='gameplay'>
            <div className='gamelogo'><img src={canjecoin} alt="Coin" /></div>
            <div className='gameplaydiv'>CANJETOKEN</div>
            
        
        </div>


        
        <div className='quantity'>
                
                            <div>
                                <label>Quantity:</label>
                                <input
                                    type="text"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    required
                                />
                            </div>
                                    
                </div>

                <button className='playButton' onClick={handleBuy}>COMPRAR</button>
                

                <div className='prom'>
            <div className='wicon'><img src={wiconlogo} alt="Coin" /></div>
                <div className='promdiv'>
                    LOS CANJETOKENS SON UNA MONEDA COLECCIONABLE EN VENTA QUE PUEDE SER 
                     CANJEADA POR CRIPTOMONEDAS.
                </div>
                


            </div>
        
      </div>
      


        
        </div>

        </>
    );
}

export default Bstor;
