import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSocket } from './socketcont';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import './aftercheckout.css';

import coinpng from './pngs/2473294.png'
import payment from './pngs/bpayment.png'


import logox from './pngs/744018x.png'
import logoo from './pngs/744018o.png'

import wiconlogo from './pngs/6897039.png'

function Aftercheckout() {
    const { socket, games, userName, balance, setGames, userId, IDplayer } = useSocket();
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedLottocoins } = location.state || { selectedLottocoins: '' };
    const [totalCost, setTotalCost] = useState(0);
    const [showBtcAddress, setShowBtcAddress] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    
    
    const { purchaseStatus } = location.state || { purchaseStatus: null };

    useEffect(() => {
        if (purchaseStatus === 'success') {
            console.log('Purchase was successful');
        } else if (purchaseStatus === 'error') {
            console.log('Error sending purchase information');
        }
    }, [purchaseStatus]);

    /*useEffect(() => {
        if (selectedLottocoins) {
            const cost = calculateTotalCost(selectedLottocoins);
            setTotalCost(cost);
        }
    }, [selectedLottocoins]);*/

    const calculateTotalCost = (coins) => {
        const rate = 1 / 100; // $1 per 100 coins
        return (parseInt(coins, 10) * rate).toFixed(2);
    };

    const handleLogout = () => {
        const token = document.cookie.split('token=')[1];
        if (token) {
            const decoded = jwtDecode(token);
            console.log('Token:', token);
            console.log('Decoded:', decoded);
            if (decoded && decoded.userId) {
                console.log('Emitting logout event');
                socket.emit('logout', { token }, (response) => {
                    if (response.success) {
                        document.cookie = 'token=; Max-Age=0'; // Delete token
                        navigate('/login'); // Redirect to login page
                    } else {
                        console.error('Logout failed:', response.message);
                    }
                });
            } else {
                console.error('Invalid token');
            }
        } else {
            console.error('No token found');
        }
    };

    const handleBuy = async () => {
        if (selectedLottocoins) {
            try {
                const response = await axios.post('https://ablaze-tar-grasshopper.glitch.me/sendPurchaseInfo', {
                IDplayer,
                email,
                lottocoins: selectedLottocoins,
                status: 'Pending'
                });
                if (response.data.success) {
                    alert('Purchase information sent successfully');
                    /*navigate('/checkout', { state: { selectedLottocoins } });*/
                } else {
                    alert('Error sending purchase information');
                }
            } catch (error) {
                console.error('Error sending purchase information:', error);
                alert('Error sending purchase information');
            }
        } else {
            alert('Please select a Lottocoins amount.');
        }
    };

    const handlePaymentButtonClick = () => {
        setShowBtcAddress(true);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/sendPurchaseInfo', {
                IDplayer,
                email,
                lottocoins: selectedLottocoins,
                status: 'Pending'
            });
            if (response.data.success) {
                alert('Purchase information sent successfully');
            } else {
                alert('Error sending purchase information');
            }
        } catch (error) {
            console.error('Error sending purchase information:', error);
            alert('Error sending purchase information');
        }
    };

    return (
        <>
        <div className="containerAfterCheckout">
            <div className='topmenuPage'>
            <div className='logoName'>
        
        <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
        <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>


        Tic Tac Toe Simple <div className='beta'>BETA</div>
    </div>
                <div className='cuentaPageBalance'><strong>Balance:&nbsp;</strong>
                <div className='mono'> {balance.toFixed(2)}</div></div>
                <div className='cuentaPage'>
                    <button className='logoutButton' onClick={handleLogout}>Logout</button>
                </div>
            </div>
            <div className='secondtopmenuBPage'>
                <div className='logoNameBot'><div className='twomono'>Hello:</div>
                <strong> {userName}</strong></div>
                <Link className='buybutton' to='/historybuy'>HISTORIAL COMPRA</Link>
            </div>
            <div className='eggdiv'>
                <div className='buytitle'>COMPRA <div className='gcoin'><img src={coinpng} alt="Coin" /></div>SIMPLETOKENS</div>
                {/*<div className='buyinfo'>100 SIMPLETOKENS = $ 1 USD</div>*/}
                <div className='aftercheck'>
                    {purchaseStatus === 'success' && <div className='aftercheckdiv'>
                        Purchase was successful
                        <br></br>
                        Status: Pending
                    </div>}
                    {purchaseStatus === 'error' && <div className='aftercheckdiv'>Error sending purchase information</div>}
                </div>
                <div>
                <div className='bottestComprar'><Link to='/depag' className='buyComprar'>Jugar</Link></div>
                </div>
            </div>

            <div className='prom'>
            <div className='wicon'><img src={wiconlogo} alt="Coin" /></div>
                <div className='promdiv'>
                    COMPRA REALIZADA. ESPERA UN MAXIMO DE 10 MINUTOS A QUE TU COMPRA SEA CONFIRMADA Y 
                    TUS BALANCES SEAN ACTUALIZADOS Y ESTEN DISPONIBLES.
                </div>
                


            </div>
            
        </div>

        <div className="containerAfterCheckoutSmall">
        <div className='topmenuPage'>
      
      <div className='logoName'>
{/*<div className='gcoin'><img src={logo} alt="Coin" /></div>*/}

       <div className='glogos'>
          <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>

          <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>

      </div>

      <div className='namelogo'>
          TICTACTOE SIMPLE
       </div>

      <div className='beta'>BETA</div>
  </div>

  </div>




    <div className='topmenuPage'>


      <div className='cuentaPageBalance'>
          <strong>Balance:&nbsp;</strong> <div className='mono'>{balance.toFixed(2)}</div>
      </div>

      <div className='cuentaPageName'>
          <div className='logoNameBot'><div className='twomono'>Hello:</div> <strong>{userName}</strong></div>
      </div>

      </div>

  <div className='secondtopmenuPage'>

      <div className='buttonscuentaPage'>


      <Link className='buybutton' to='/historybuy'> <strong>HISTORIAL COMPRA</strong></Link>
      <button className='logoutButton' onClick={handleLogout}>Logout</button>
      </div>

  </div>  
            <div className='eggdiv'>
                <div className='buytitle'>COMPRA <div className='gcoin'><img src={coinpng} alt="Coin" /></div>SIMPLETOKENS</div>
                {/*<div className='buyinfo'>100 SIMPLETOKENS = $ 1 USD</div>*/}
                <div className='aftercheck'>
                    {purchaseStatus === 'success' && <div className='aftercheckdiv'>
                        Purchase was successful
                        <br></br>
                        Status: Pending
                    </div>}
                    {purchaseStatus === 'error' && <div className='aftercheckdiv'>Error sending purchase information</div>}
                </div>
                <div>
                <div className='bottestComprar'><Link to='/depag' className='buyComprar'>Jugar</Link></div>
                </div>
            </div>

            <div className='prom'>
            <div className='wicon'><img src={wiconlogo} alt="Coin" /></div>
                <div className='promdiv'>
                    COMPRA REALIZADA. ESPERA UN MAXIMO DE 10 MINUTOS A QUE TU COMPRA SEA CONFIRMADA Y 
                    TUS BALANCES SEAN ACTUALIZADOS Y ESTEN DISPONIBLES.
                </div>
                


            </div>
            
        </div>


        </>
    );
}

export default Aftercheckout;
