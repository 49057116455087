import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSocket } from './socketcont';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import './historybuyct.css';

import coinpng from './pngs/2473294.png'
import payment from './pngs/bpayment.png'


import logox from './pngs/744018x.png'
import logoo from './pngs/744018o.png'

function Historygame() {
    const { socket, games, userName, balance, setGames, userId, IDplayer } = useSocket();
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedLottocoins } = location.state || { selectedLottocoins: '' };
    const [totalCost, setTotalCost] = useState(0);
    const [showBtcAddress, setShowBtcAddress] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    
    
    const [gameHistory, setGameHistory] = useState([]); // Renamed from `game` to `gameHistory`

    useEffect(() => {
        const fetchGameHistory = async () => { // Correct function name
            const token = document.cookie.split('token=')[1];
            if (token) {
                try {
                    const response = await axios.get('https://ablaze-tar-grasshopper.glitch.me/getGameHistory', {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    if (response.data.success) {
                        console.log(response.data.history);
                        setGameHistory(response.data.history); // Updated state
                    } else {
                        console.error('Error fetching game history:', response.data.message);
                    }
                } catch (error) {
                    console.error('Error fetching game history:', error);
                }
            } else {
                console.error('No token found');
            }
        };

        fetchGameHistory();
    }, []);


    const handleLogout = () => {
        const token = document.cookie.split('token=')[1];
        if (token) {
            const decoded = jwtDecode(token);
            console.log('Token:', token);
            console.log('Decoded:', decoded);
            if (decoded && decoded.userId) {
                console.log('Emitting logout event');
                socket.emit('logout', { token }, (response) => {
                    if (response.success) {
                        document.cookie = 'token=; Max-Age=0'; // Delete token
                        navigate('/login'); // Redirect to login page
                    } else {
                        console.error('Logout failed:', response.message);
                    }
                });
            } else {
                console.error('Invalid token');
            }
        } else {
            console.error('No token found');
        }
    };


    //THERE WAS A ISSUE WITH
    //LOS WAS ALSO FOR IDPLAYER
    //KIE PROBLEM?

    // IDPLAYER GAME


    return (
        <>
        <div className="containerHBuy">
            <div className='topmenuPage'>
            <div className='logoName'>
        
        <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>
        <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>


        Tic Tac Toe Simple <div className='beta'>BETA</div>
    </div>
                <div className='cuentaPageBalance'><strong>Balance:&nbsp;</strong>
                <div className='mono'> {balance.toFixed(2)}</div></div>
                <div className='cuentaPage'>
                    <button className='logoutButton' onClick={handleLogout}>Logout</button>
                </div>
            </div>
            <div className='secondtopmenuBPage'>
                <div className='logoNameBot'>
                    <div className='twomono'>Hello:</div> 
                    <strong>{userName}</strong></div>
                <div className='bottestComprar'><Link to='/depag' className='buyComprar'>Jugar</Link></div>
                <Link className='buybutton' to='/historybuy'>HISTORIAL COMPRA</Link>
            </div>
            <div className='eggdiv'>
            <h2>HISTORIAL JUEGOS</h2>
            <table className="historyTable">
                    <thead>
                        <tr>
                        <th>Game ID</th>
                         <th>Date</th>
                        <th>Amount</th>
                        <th>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gameHistory.map((game, index) => (
                            <tr key={index}>
                                <td>{game.gameId}</td>
                <td>{new Date(game.date).toLocaleDateString()}</td>
                <td>{game.gamePool}</td>
                <td>{game.outcome}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
        </div>

        <div className="containerHBuySmall">
        <div className='topmenuPage'>
      
      <div className='logoName'>
{/*<div className='gcoin'><img src={logo} alt="Coin" /></div>*/}

       <div className='glogos'>
          <div className='gcoinlogoo'><img src={logoo} alt="Coin" /></div>

          <div className='gcoinlogox'><img src={logox} alt="Coin" /></div>

      </div>

      <div className='namelogo'>
          TICTACTOE SIMPLE
       </div>

      <div className='beta'>BETA</div>
  </div>

  </div>




    <div className='topmenuPage'>


      <div className='cuentaPageBalance'>
          <strong>Balance:&nbsp;</strong> <div className='mono'>{balance.toFixed(2)}</div>
      </div>

      <div className='cuentaPageName'>
          <div className='logoNameBot'><div className='twomono'>Hello:</div> <strong>{userName}</strong></div>
      </div>

      </div>

  <div className='secondtopmenuPage'>

      <div className='buttonscuentaPage'>


      <Link className='buybutton' to='/depag'> <strong>Jugar</strong></Link>
      <button className='logoutButton' onClick={handleLogout}>Logout</button>
      </div>

  </div>  
            <div className='eggdiv'>
            <div className='h2'>HISTORIAL JUEGOS</div>
            <table className="historyTable">
                    <thead>
                        <tr>
                        <th>Game ID</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Result</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gameHistory.map((game, index) => (
                            <tr key={index}>
                                <td>{game.gameId}</td>
                                <td>{new Date(game.date).toLocaleDateString()}</td>
                                <td>{game.gamePool}</td>
                                <td>{game.outcome}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
        </div>

        </>
    );
}

export default Historygame;
